.manufacturer {
  .brand {
    background-color: $grey-lighter;
    color: $black;
    font-size: 14px;
    margin-top: 30px;
    padding: 30px 0;

    @media (min-width: $sm-width) {
      font-size: 16px;
      margin-top: 60px;
      padding: 60px 0;
    }

    &-title {
      @include heading($blue-dark, 1.8, 1, 900, false);

      text-align: center;

      @media (min-width: $sm-width) {
        font-size: 2.4rem;
        margin-bottom: 3rem;
      }
    }

    &-content {
      margin: 0 auto 15px;
      max-width: 100%;
      width: 630px;

      @media (min-width: $sm-width) {
        margin-bottom: 30px;
      }
    }

    .buttons-ctn {
      margin: 0 -15px 20px;

      @media (min-width: $sm-width) {
        margin-bottom: 40px;
      }
    }

    .button {
      font-size: 14px;
      margin: 0 15px 15px;
    }

    h2 {
      color: $blue-dark;
    }
  }
}
