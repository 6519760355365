.facilities,
.facilities-credit {
  background: $grey-lighter;
  font-size: 1.2rem;
  margin: 0 -1.5rem;
  padding: 0 1.5rem;

  @media (min-width: $md-width) {
    padding: 0 2.5rem;
  }

  @media (min-width: $lg-width) {
    font-size: 1.4rem;
    margin: 0 -3rem;
  }
}

.facilities {
  color: $black;
  font-weight: 900;
  padding: 1rem 1.5rem;
  text-align: center;

  @media (min-width: $md-width) {
    padding: 1rem 2.5rem;
  }
}

.facilities,
.oney-simulation {
  .credit_legal {
    display: block;
    font-size: 2rem;
    margin-top: 15px;
  }

  .nb_mens {
    background-color: $oney-primary-color;
    border-radius: 50%;
    color: white;
    padding: 3px 4px;
  }

  .payment_x_price .price,
  .oney-panel-btn {
    color: $oney-primary-color;
  }

  .payment-choice-inner & {
    margin: 0;
    padding: 0;
  }

  .pin {
    @include size(2.8rem);
    background: $oney-primary-color;
    border-radius: 50%;
    color: $white;
    display: inline-block;
    padding: 0.5rem 0;
    text-align: center;
  }

  .small-pin {
    @include size(2rem);
    font-weight: 700;
    padding: 0;
  }

  .amount {
    color: $oney-primary-color;
  }

  .oney_logo {
    display: inline;
    vertical-align: middle;
  }

  .facilities-link {
    color: $oney-primary-color;
    text-decoration: none;
  }

  .payment_x_label_fees:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.oney-simulation {
  .highlight {
    color: $oney-primary-color;
    font-weight: 700;
  }

  .value {
    color: $oney-primary-color;
  }
}

.payment-simulation {
  text-align: left;

  .panel-subblock {
    .panel-sub-title {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      vertical-align: baseline;
    }
  }

  .logo {
    display: inline-block;
    margin-bottom: -10px;
    margin-inline-start: 7px;
    vertical-align: baseline;
    width: 15%;
  }

  .credit_legal {
    text-transform: uppercase;
  }

  &:hover {
    text-decoration: none;
  }

  .simResult {
    li {
      line-height: 24px;
    }

    .label {
      color: $grey-medium;
      margin-right: 40px;
    }

    span {
      color: $grey-medium;
    }

    .value {
      font-weight: 700;
    }

    .mensualites,
    .creditinfo,
    .teg {
      .value {
        font-size: 2rem;
      }

      span.TAEG {
        font-size: 2rem;
        font-weight: 700;
      }
    }
  }

  &-mentions {
    color: $grey-medium;
    font-size: 1.2rem;
    font-style: italic;
    line-height: 1.4rem;

    @media (min-width: $md-width) {
      margin-top: 10rem;
    }
  }

  .radio-ctn {
    input[type="radio"] {
      appearance: radio;
      display: inline-block;
    }
  }
}

#order,
#product {
  span.oney_simulation {
    color: #75c000;
    font-size: 14px;
  }

  a {
    text-decoration: none;
  }

  .cgv a {
    text-decoration: underline !important;
  }

  .oney_payment {
    float: right;
    margin: 15px 0 0;
    padding-left: 10px;
    position: relative;
    text-align: left;
    width: 170px;

    a.oney_payment_methode {
      clear: both;
      color: #4d4d4d;
      display: block;
      float: left;
      padding-left: 10px;
      width: 100%;

      img {
        float: left;
      }

      span {
        display: block;
        float: left;
        height: 40px;
        line-height: 40px;
      }

      span.small {
        font-size: 10px;
        font-style: italic;
        line-height: 1em;
        margin-right: 10px;
        margin-top: -5px;
        padding-left: 30px;
        text-align: left;
      }
    }
  }

  .oney_overlay {
    background: rgba(0, 0, 0, 75%);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .oney_overlay.shown {
    display: block;
  }

  .oney_popup {
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 50%);
    display: none;
    margin-left: -180px;
    padding: 0 0 20px;
    position: absolute;
    right: 170px;
    top: -30px;
    width: 360px;
    z-index: 110;

    .img {
      text-align: center;
    }

    .desc {
      .title {
        background: #000;
        color: #fff;
        display: inherit;
        font-size: 13px;
        font-style: italic;
        font-weight: bold;
        line-height: 1.4em;
        margin-bottom: 10px;
        padding: 6px 20px;
        text-transform: uppercase;
      }

      a.close {
        display: none;
      }

      .desc {
        font-size: 15px;
        padding: 0 20px;
      }

      .oney_more {
        color: #22205f;
        font-size: 13px;
        font-style: italic;
        padding: 0 20px;
      }
    }

    .oney_more_details {
      display: none;
      margin-top: 20px;
      position: relative;

      .oney_less {
        color: #22205f;
        float: right;
        margin-left: 10px;
        position: absolute;
        right: 15px;
        text-transform: none;
        top: 4px;
      }

      ul {
        list-style-type: none;
      }

      li.total {
        color: #22205f;
        font-weight: bold;
        text-transform: uppercase;
      }

      li {
        span {
          display: inline-block;
          width: 120px;
        }
      }
    }
  }

  .oney_popup.shown {
    display: block;
  }
}

#oneySimulation {
  #oneySimulationFrom {
    div.sub_title {
      background: #22205f;
      color: #fff;
      font-size: 16px;
      height: 55px;
      line-height: 54px;
      margin: 15px -15px;
      margin-bottom: 20px;
      padding: 0 15px;
      text-transform: uppercase;
    }

    div.form-group {
      margin-bottom: 20px;

      span.label {
        display: inline-block;
        text-align: left;
        width: 300px;
      }

      span.label.row {
        display: inline-block;
        font-weight: bold;
        width: auto;
      }

      .highlight {
        color: #22205f;
        font-weight: bold;
      }

      p.radio {
        label {
          cursor: pointer;
          display: inline;
          font-size: 16px;
          line-height: 1.5em;
        }

        input[type="radio"] {
          cursor: pointer;
        }
      }
    }

    .simResult {
      span {
        color: #333;
        display: inline;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.5em;
      }

      ul {
        margin-top: 0;

        li {
          color: #4d4d4d;
          list-style-type: none;

          span {
            color: #333;
            display: inline;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5em;
          }
        }
      }
    }
  }
}

.oney_mentions_content.error,
.result-simulation.error {
  display: none;
}

.result-simulation.loading {
  filter: blur(2px);
  opacity: 0.5;
}
